@import "src/app/core/scss/variables";
@import "src/app/core/scss/functions";

$content-text-base: #1E252B;
$content-text-link: #5AB3E4;
$content-table-border: #5AB3E4;
$content-table-th-ground: #E6F6FF;
$content-table-th-text: #6D7381;

$font-family-regular: 'LatoWeb', sans-serif;
$font-family-medium: 'LatoWebMedium', sans-serif;
$font-family-bold: 'LatoWebBold', sans-serif;

:host {
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
}

h1, h2, h3, h4 {
  font-family: $font-family-bold;
  color: $content-text-base;
  line-height: 150%;
  margin: 0;
}
h1 {
  font-size: 36px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

p {
  color: $content-text-base;
  margin: 0;
}

a {
  color: $content-text-link;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

li {
  color: $content-text-base;

  gamelib-gap-dropbox,
  gamelib-gap-inputbox {
    margin: 2px 10px 0 10px!important;
  }
}

ul {
  list-style-image: url(assetsUrl('/assets/icons/list_style_image.svg'));
  margin:0;
  padding: 0 0 0 26px;
}

ol {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}

figure { // @deprecated
  margin: 0;
  width: fit-content;
}

figcaption { // @deprecated
  color: #1E252B;
  text-align: center;
}

table {
  border: 1px solid $content-table-border;
  border-collapse: collapse;
  margin: 20px -1px;
  table-layout: fixed;
  max-width: calc(100% + 2px) !important;
  border-radius: 10px;
  overflow: hidden;

  caption {
    padding-top: 20px !important; // проверить по макету !!!
    font-size: 14px !important;
    line-height: 130%;
    color: #A4B0C3 !important;
    margin-bottom: 10px; // проверить по макету !!!
  }
}

th {
  font-family: $font-family-medium;
  background: $content-table-th-ground;
  border: 1px solid $content-table-border;
  color: $content-text-base;
  font-weight: 700;
  line-height: 150%;
  padding: 5px;
}

td {
  border: 1px solid $content-table-border;
  color: $content-table-th-text;
  line-height: 150%;
  padding: 5px;
  text-align: center;
}

img {
  border-radius: 10px;
  max-width: 100%;
  object-fit: cover;
  object-position: center;

  display: block;
  margin: 0 auto;
}

gamelib-gap-dropbox {
  > .gap {
    margin: 0;
  }
}
