/* It's blank, but don't remove
*/
.__theme {
  .app-main app-header .header {
    background-color: #5ab3e4;

    .brand .logo {
      background: url(assetsUrl('/assets/images/logo.svg')) no-repeat;
    }
  }

  .app-main app-left-sidebar .left-sidebar-floating .menu {
    .logo {
      background: url(assetsUrl('/assets/images/logo.svg')) no-repeat;
    }
  }

  .app-main app-footer .footer {
    background-color: #5ab3e4;
  }

  .app-main app-mobile-footer .mobile-footer {
    background-color: #5ab3e4;
  }
}
