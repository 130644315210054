$onclass-blue: (
    50 : #ebf6fc,
    100 : #cee8f7,
    200 : #add9f2,
    300 : #8ccaec,
    400 : #73bee8,
    500 : #5ab3e4,
    600 : #52ace1,
    700 : #48a3dd,
    800 : #3f9ad9,
    900 : #2e8bd1,
    A100 : #ffffff,
    A200 : #e0f1ff,
    A400 : #addaff,
    A700 : #94ceff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$onclass-green: (
    50 : #f1faef,
    100 : #dbf2d8,
    200 : #c3e9be,
    300 : #abe0a3,
    400 : #99da90,
    500 : #87d37c,
    600 : #7fce74,
    700 : #74c869,
    800 : #6ac25f,
    900 : #57b74c,
    A100 : #ffffff,
    A200 : #e3ffe0,
    A400 : #b5ffad,
    A700 : #9eff94,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);