li.iti__country:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.iti__selected-flag.dropdown-toggle:after {
	content: none;
}

.iti__flag-container.disabled {
	cursor: default !important;
}

.iti.iti--allow-dropdown .flag-container.disabled:hover .iti__selected-flag {
	background: none;
}

.country-dropdown {
	border: 1px solid #ccc;
	width: fit-content;
	padding: 1px;
	border-collapse: collapse;
}

.search-container {
	position: relative;
}

.search-container input {
	width: 100%;
	border: none;
	border-bottom: 1px solid #ccc;
	padding-left: 10px;
}

.search-icon {
	position: absolute;
	z-index: 2;
	width: 25px;
	margin: 1px 10px;
}

.iti__country-list {
	position: relative;
	border: none;
}

.iti input#country-search-box {
	padding-left: 6px;
}

.iti .selected-dial-code {
	margin-left: 6px;
}

.iti.separate-dial-code .iti__selected-flag,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-2 .iti__selected-flag,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-3 .iti__selected-flag,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 .iti__selected-flag {
	width: 93px;
}

.iti.separate-dial-code input,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-2 input,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-3 input,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 input {
	padding-left: 98px;
}
