/* gap-inputbox/dropbox/selectbox plugin */

gap-inputbox {
  box-sizing: border-box;
  border: 1px solid #EEEEEE;
  padding: 0 5px;
  // margin: 1px 10px;
  // margin: 1px 0;
  margin: 0;
  border-radius: 5px;
  display: inline-block;
  line-height: 145% !important;
  cursor: pointer;
}

gap-dropbox {
  box-sizing: border-box;
  background-color: #5AB3E4;
  padding: 0 5px;
  // margin: 1px 10px;
  // margin: 1px 0;
  margin: 0;
  border-radius: 5px;
  color: #FFFFFF;
  display: inline-block;
  line-height: 145% !important;
  cursor: pointer;
}

gap-selectbox {
  box-sizing: border-box;
  background-color: #5AB3E4;
  // background-image: url(/assets/icons/gap_selectbox_dots.svg);
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='2' cy='2' r='2' fill='white'/%3E%3Ccircle cx='2' cy='8' r='2' fill='white'/%3E%3Ccircle cx='2' cy='14' r='2' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 10px auto;
  background-position: right 6px;
  padding: 0 15px 0 5px;
  // margin: 1px 10px;
  // margin: 1px 0;
  margin: 0;
  border-radius: 5px;
  color: #FFFFFF;
  display: inline-block;
  line-height: 145% !important;
  cursor: pointer;
  min-height: 1.6em;
}
gap-selectbox gap-variant {display: none}
gap-selectbox gap-variant[selected="selected"] {display: inline}

gap-inputbox + gap-inputbox,
gap-inputbox + gap-dropbox,
gap-inputbox + gap-selectbox,
gap-dropbox + gap-dropbox,
gap-dropbox + gap-inputbox,
gap-dropbox + gap-selectbox,
gap-selectbox + gap-selectbox,
gap-selectbox + gap-inputbox,
gap-selectbox + gap-dropbox {
  margin-left: 0;
}

gap-inputbox:first-child,
gap-dropbox:first-child,
gap-selectbox:first-child {
  margin-left: 0;
}

/** table plugin */
table {
  border-spacing: 0;
  border: 1px solid #5AB3E4;
  border-radius: 10px;
  border-collapse: separate !important;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
}
table td {
  padding: 10px;
  border-left: 0;
  border-top: 0;
  color: #6D7381;
}
table tr:first-child td {
  background: #E6F6FF;
  color: #1E252B;
  font-family: LatoWebBold,sans-serif;
  font-weight: 700;
  border-top: none;
}
table tr:last-child td {
  border-bottom: none;
}
table tr td:last-child {
  border-right: none;
}
table td:first-child, table th:first-child {
  border-left: none;
}
table tr:first-child td:first-child {
  border-top-left-radius: 10px;
}
table tr:first-child td:last-child {
  border-top-right-radius: 10px;
}

/** columns plugin */
section.columns {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;

  > div.column {
    flex: 1
  }
  div.column + div.column {
    margin-left: 20px;
  }
}

/** image plugin */
figure.image {
  // max-width: 730px; https://app.clickup.com/t/e91a1k

  figcaption {
    padding-top: 10px !important;
    font-size: 14px !important;
    line-height: 130%;
    color: #A4B0C3 !important;
    text-align: center;
  }
}

figure.align-left {
  float: left;
  width: 50% !important;
  height: 100% !important;
  margin: 10px 30px 10px 0;
}

figure.align-center {
  display: block;
  width: 100%;
  margin: 10px auto;
}

figure.align-right {
  float: right;
  width: 50% !important;
  height: 100% !important;
  margin: 10px 0 10px 30px;
}

img.align-left {
  float: left;
  width: 50% !important;
  height: 100% !important;
  margin: 10px 30px 10px 0;
}

img.align-right {
  float: right;
  width: 50% !important;
  height: 100% !important;
  margin: 10px 0 10px 30px;
}

img.align-center {
  width: 100%;
  max-width: 100%;
  height: auto !important;
  margin: 10px 0;
}

iframe {
  border: none;
  border-radius: 10px;
  // height: auto;
  width: 100%;
}

//__<<ngThemingMigrationEscapedComment4>>__
//#aria-owns_2303449411561588783963756 .tox-swatches__row {
//  display: none;
//}

/** blockquote-colors plugin */
blockquote[class^="blockquote-"] {
  padding: 20px;
  margin: 0 0 20px;
  border-radius: 10px;
}
.blockquote-pink   { background-color: #F9CDCD; }
.blockquote-yellow { background-color: #FCEFCA; }
.blockquote-white { background-color: #FFFFFF; }
.blockquote-transparent { background-color: transparent; }
.blockquote-blue   { background-color: #C0E2F5; }
.blockquote-green  { background-color: #D4F1E0; }
.blockquote-purple { background-color: #E1CBF6; }
.blockquote-orange { background-color: #FCE1C9; }

/** marker-colors plugin */
marker[class^="marker-"] { display: inline; }
span[class^="marker-"] { display: inline; }
.marker-black      { background-color: #3D4246; }
.marker-gray       { background-color: #747882; }
.marker-white       { background-color: #FFFFFF; }
.marker-transparent { background-color: transparent; }
.marker-blue       { background-color: #80C1E5; }
.marker-red        { background-color: #EA979E; }
.marker-green      { background-color: #97E28D; }
.marker-yellow     { background-color: #F3E47F; }

/** span-colors plugin @deprecated */
span[class^="span-"] { display: inline; }
.span-black        { color: #1E252B; }
.span-gray         { color: #6D7381; }
.span-white        { color: #FFFFFF; }
.span-blue         { color: #5AB3E4; }
.span-red          { color: #E86F79; }
.span-green        { color: #87D37C; }
.span-yellow       { color: #FFDD00; }


/** pen-colors plugin */
span[class^="pen-"] { display: inline; }
.pen-black         { color: #1E252B; }
.pen-gray          { color: #6D7381; }
.pen-white         { color: #FFFFFF; }
.pen-blue          { color: #5AB3E4; }
.pen-red           { color: #E86F79; }
.pen-green         { color: #87D37C; }
.pen-yellow        { color: #FFDD00; }


/* только в режиме редактирования */
.mce-content-body {
  /* Хак для tinymce в конструкторе урока @see: https://app.clickup.com/t/8692pd04h */
  content: "";
  clear: both;
  display: table;

  p {
    img {
      margin: 10px;
    }
  }

  figure {
    &.align-left {
      width: auto !important;
      height: auto !important;
      margin: 10px;
    }

    &.align-right {
      width: auto !important;
      height: auto !important;
      margin: 10px;
    }
  }

  /** columns plugin */
  section.columns {
    > div.column {
      &:before {
        content: '';
        display: block;
        height: 4px;
        border-top: 1px dashed #999;
        border-left: 1px dashed #999;
        border-right: 1px dashed #999;
      }

      &:after {
        content: '';
        display: block;
        height: 4px;
        border-bottom: 1px dashed #999;
        border-left: 1px dashed #999;
        border-right: 1px dashed #999;
      }
    }
  }
}
