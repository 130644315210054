.light {
  font-family: $font-family-light;
  font-weight: inherit;
}

.normal {
  font-family: $font-family-regular;
  font-weight: inherit;
}

.bolder, .medium {
  font-family: $font-family-medium;
  font-weight: inherit;
}

h1, h2, h3, h4, h5, h6, strong, b, .bold, .strong {
  font-family: $font-family-bold;
  font-weight: inherit;
}

* {
  // убирает синюю подсветку на мобильных устройствах при нажатии на элемент
  -webkit-tap-highlight-color: transparent;
}

body {
  position: relative;
  scroll-behavior: smooth;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
}

.rich-html, .rich-editor {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  word-wrap: break-word;

  span {
    display: block;
    line-height: 150%;
  }

  * {
    -webkit-touch-callout: none !important;
  }

  pre:not(.input) {
    white-space: normal;
  }

  .mce-object-iframe {
    width: 100%;
  }

  @import "article";
  @import "plugins";
}

@import "mathtype";
