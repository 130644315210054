@import '@angular/cdk/overlay-prebuilt.css';
@import "app/core/scss/main";
@import "src/app/core/scss/variables";
@import "src/app/core/scss/functions";
@import "src/app/core/scss/vendor/mat-onclass-theme.scss";
@import "src/app/core/scss/vendor/plyr.scss";
@import "app/core/components/seo/seo-helper/seo-helper-global";
@import "node_modules/angular-calendar/css/angular-calendar.css";
@import "node_modules/ngx-intl-phone-input/src/lib/bootstrap-dropdown.css";
@import "node_modules/ngx-intl-phone-input/src/lib/ngx-intl-phone-input.component.css";
@import "node_modules/intl-tel-input/build/css/intlTelInput.css";
@import "node_modules/ngx-toastr/toastr.css";
@import "__theme.scss";


/* Todo. Move to new css
*/
.icon__def {
  width: 12px;
}
.button__slave--icon {
  cursor: pointer;

  display: flex;
  border-radius: 20px;
  border: 1px solid #ddd;
  padding: 4pt;
  min-width: 135px;
  width: 135px;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  min-height: 26px;
  color: #3c589e;
}


/* Todo. Review all css below
*/
html {
  scroll-behavior: smooth;

  // position: fixed;
  // height: 100%;
  // overflow: hidden;
  /* overflow-x: hidden;  так нельзя ломает календарь */
  overscroll-behavior: none none;
  width: 100vw;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family-regular;
  color: $black;
  font-size: $font-size;
  font-weight: normal;
  line-height: $line-height;
  background: $white;
  min-width: $min-width;
  position: relative;

  overflow-y: auto;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;
  overscroll-behavior: none none;
  -webkit-overflow-scrolling: touch;

  &.fixed {
    overflow-y: hidden;
    // margin-left: calc((1rem + 1em) / 2 * -1);
  }

  &.mobile {
    &.fixed {
      margin-left: unset;
      position: fixed;
      width: 100%;
      height: 100vh;
      overflow: hidden;
    }
  }
}

a:focus {
  outline: none;
}

/** тюнинг размеров пунктов меню навигации в шапке страницы */
html[lang="ru"] {
  app-header .nav .nav-item {
    width: 90px !important;
  }
}
/**
  * Хаки стилей для говно-компоненты <app-suggest-language-change>
  * Простите меня, пожалуйста
  */
main.app-body.suggest-language-change,
main.app-body.vacation-informer,
main.app-body.suggest-add-card {
  .left-sidebar {
    top: 138px;
  }

  .right-sidebar {
    top: 0px;
  }
}

@media(max-width: 870px) {
  main.app-body.suggest-language-change,
  main.app-body.vacation-informer,
  main.app-body.suggest-add-card {
    .right-sidebar {
      top: 0;

      .right-sidebar-close {
        top: 100px;
      }
    }
  }
}


// Стили для превью drag and drop, нужны либо здесь
// либо в компоненте где используется viewEncapsulation: none

.constructor-collection {
  box-sizing: border-box;
  border-radius: 10px;
  background: $white;
  opacity: .8;
  height: auto !important;
  box-shadow: $shadow-card;

  .drag-handler {
    position: absolute;
    cursor: move;
    z-index: 5;
    right: 4px;
    top: 4px;
    padding: 10px;
  }

  .link-delete {
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    color: #E86F79;
  }

  .private-label {
    display: none;
    top: 50px;
    right: 18px;
    position: absolute;
    border-radius: 28px;
    background: rgba(30, 37, 43, 0.6);
    font-size: 12px;
    padding: 2px 8px;
    line-height: 14px;
    color: $white;
    z-index: 2;
    border: 1px solid #aaa;
    box-sizing: border-box;
  }

  &.private {
    .private-label {
      display: block;
    }

    ::ng-deep {
      .content-card-mini-readonly {
        border: 1px solid #1E252B;
      }
    }
  }
}

// fix https://app.clickup.com/t/33tdr1b
#credential_picker_container {
  display: none !important;
  pointer-events: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}


// fix google auth button design in login modal
asl-google-signin-button {
  iframe {
    width: 175px !important;

    #container {

      .jVeSEe {
        min-width: 100px !important;

        .nsm7Bb-HzV7m-LgbsSe-BPrWId {
          .ssJRIf {
            width: 85px !important;
          }

          .K4efff {
            width: 85px !important;
          }
        }
      }
    }
  }
}

// hide Google Auth widget for mobile
#credential_picker_iframe {
  display: none;
}

.iti {
  display: flex !important;
  margin-bottom: 0;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 244px;
  max-height: 170px;
}

.iti__flag-container {
  position: relative;
}

.iti__flag-container.open + input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

ngx-intl-phone-input input {
  height: 44px;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-size: 18px;
}

ngx-intl-phone-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

ngx-intl-phone-input input:hover {
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-phone-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-phone-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-phone-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-phone-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-phone-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-phone-input input[disabled] {
  background-color: #e5eaf1;
}
