@import "src/app/core/scss/variables";
@import "src/app/core/scss/functions";

/* Webfont: Lato-Bold */
@font-face {
  font-family: 'LatoWebBold';
  font-display: swap;
  src: url('/assets/fonts/Lato-Bold.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
       url('/assets/fonts/Lato-Bold.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */
@font-face {
  font-family: 'LatoWebMedium';
  font-display: swap;
  src: url('/assets/fonts/Lato-Medium.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/Lato-Medium.woff2') format('woff2'), /* Modern Browsers */
       url('/assets/fonts/Lato-Medium.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/Lato-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */
@font-face {
  font-family: 'LatoWeb';
  font-display: swap;
  src: url('/assets/fonts/Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
       url('/assets/fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Light */
@font-face {
  font-family: 'LatoWebLight';
  font-display: swap;
  src: url('/assets/fonts/Lato-Light.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Lato-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/Lato-Light.woff2') format('woff2'), /* Modern Browsers */
       url('/assets/fonts/Lato-Light.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/Lato-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
