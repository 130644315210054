.xs, .sm, .md, .lg {
  display: none;
}
@media (min-width: 920px) { // width >= 920
  .lg { display: block; }
}

@media (min-width: 640px) and (max-width: 919px) { // width >= 640 && width < 920
  .md { display: block; }
}

@media (min-width: 360px) and (max-width: 639px) { // width >= 360 && width < 640
  .sm { display: block; }
}

@media (max-width: 359px) { // width >= 0 && width < 360
  .xs { display: block; }
}
