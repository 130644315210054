
@mixin active {
  background: #5AB3E4 !important;  
  color: #FFFFFF !important;   
}

.mat-autocomplete-panel:not(.multi-select) {

    border-radius: 10px;
    padding: 10px 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;

    margin-top: 5px;

    .mat-option {
      height: 26px;
      line-height: 26px;

      color: #181C2F;
      cursor: pointer;
      
      font-size: 14px;
      font-weight: 400;
     
      padding: 0 10px;

      &:hover {
        @include active;
      }

      &.mat-active {
        @include active;
      }
    }
}

.multi-select { 
  &.mat-autocomplete-panel {
  
      border-radius: 10px;
      padding: 10px 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  
      margin-top: 5px;
  
      .mat-option {
        height: 54px;
        line-height: 26px;
  
        color: #181C2F;
        cursor: pointer;
        
        font-size: 14px;
        font-weight: 400;
       
        padding: 0 10px;
      }
  }
  
}