@mixin clearfix() {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

@mixin reset-text {
  font-family: $font-family-base;
  // We deliberately do NOT reset font-size or word-wrap.
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  text-align: left; // Fallback for where `start` is not supported
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
}

@mixin de-buttonize {
  display: inline-block;
  margin: 0;
  padding: 0;
  font: inherit;
  color: inherit;
  font-weight: 400;
  line-height: 1em;
  text-align: left; // Fallback for where `start` is not supported
  text-align: start;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  outline: none;
  user-select: none;
  cursor: pointer;
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin blur($size: 5px) {
  -webkit-filter: blur($size);
  -moz-filter: blur($size);
  filter: blur($size);
  filter: url('#blur5px');
}

/**
  OnClass UIKit
  Тирографика
**/

@mixin title($title: 'h2') {
  color: $black;
  font-family: $font-family-bold;
  font-weight: inherit;
  margin: 0;

  @if ($title == 'h1') {
    font-size: 36px;
    line-height: 42px;
  } @else if ($title == 'h2') {
    font-size: 24px;
    line-height: 30px;
  } @else if ($title == 'h3') {
    font-size: 22px;
    line-height: 26px;
  } @else if ($title == 'h4') {
    font-size: 18px;
    line-height: 24px;
  } @else if ($title == 'h5') {
    font-size: 16px;
    line-height: 20px;
  }
}

@mixin text($text: 'base') {
  color: $black;
  font-family: $font-family-regular;
  font-weight: inherit;
  margin: 0;

  @if ($text == 'large') {
    font-size: 18px;
    line-height: 26px;
  } @else if ($text == 'base') {
    font-size: 16px;
    line-height: 150%;
  } @else if ($text == 'second') {
    color: $gray04;
    font-size: 14px;
    line-height: 150%;
  } @else if ($text == 'second-bold') {
    font-family: $font-family-bold;
    color: $gray04;
    font-size: 14px;
    line-height: 150%;
  } @else if ($text == 'explanatory') {
    color: $gray04;
    font-size: 12px;
    line-height: 16px;
  } @else if ($text == 'explanatory-bold') {
    font-family: $font-family-bold;
    color: $gray04;
    font-size: 12px;
    line-height: 16px;
  }
}

@mixin link($link: 'base') {
  color: $sky-darker;
  font-family: $font-family-regular;
  font-weight: inherit;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  margin: 0;

  @if ($link == 'large') {
    font-size: 16px;
    line-height: 20px;
  } @else if ($link == 'base') {
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin label($label: 'small') {
  color: $gray04;
  font-family: $font-family-regular;
  font-weight: inherit;

  @if ($label == 'big') {
    font-size: 22px;
    line-height: 22px;
  } @else if ($label == 'small') {
    font-size: 10px;
    line-height: 12px;
  }
}

@mixin ellipsis($line: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  word-break: break-word;
  line-break: normal;
}
