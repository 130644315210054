$drag-scroll-btn-ico: #41A1D9;
$drag-scroll-btn-shadow: rgba(128, 128, 128, .25);
$drag-scroll-btn-ground: #5AB3E4;
$drag-scroll-title: #1E252B;
$drag-scroll-add-btn: #F5F8FA;
$drag-scroll-tab: #A4B0C3;
$drag-scroll-active: #41A1D9;
$drag-scroll-all: #41A1D9;

.carousel {
  position: relative;

  .left-btn {
    background: $white;
    border-radius: 50%;
    box-shadow: 0px -4px 4px $drag-scroll-btn-shadow;
    cursor: pointer;
    height: 50px;
    width: 50px;
    position: absolute;
    left: 0;
    bottom: calc(50% - 25px);
    transform: rotateZ(180deg);
    user-select: none;
    z-index: 3;
    transition: all $transition-time linear;

    .left-arrow {
      fill: $white;
      stroke: $drag-scroll-btn-ico;
      height: 22px;
      margin: 14px 0 0 19px;
      width: 12px;
      transition: all $transition-time linear;
    }

    &:hover {
      background: $drag-scroll-btn-ground;

      .left-arrow {
        fill: $drag-scroll-btn-ground;
        stroke: $white;
      }
    }
  }

  .right-btn {
    background: $white;
    border-radius: 50%;
    box-shadow: 0 4px 4px $drag-scroll-btn-shadow;
    cursor: pointer;
    height: 50px;
    width: 50px;
    position: absolute;
    right: 0;
    bottom: calc(50% - 25px);
    user-select: none;
    z-index: 3;
    transition: all $transition-time linear;

    .right-arrow {
      fill: $white;
      stroke: $drag-scroll-btn-ico;
      height: 22px;
      margin: 14px 0 0 21px;
      width: 12px;
      transition: all $transition-time linear;
    }

    &:hover {
      background: $drag-scroll-btn-ground;

      .right-arrow {
        fill: $drag-scroll-btn-ground;
        stroke: $white;
      }
    }
  }

  .scroll-viewport {
    height: 270px;
    width: 830px;
    overflow-y: hidden;
    @include scrollbars(0, $white);

    .cdk-virtual-scroll-content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: normal;
      margin: 0;

      .scroll-item {
        cursor: pointer;
      }
    }
  }
}

.responsive-scroll-viewport {
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .scroll-item {
    cursor: pointer;
    display: block;
    position: relative;
    flex: 0 0;

    &:last-child {
      padding-right: 15px;
    }
  }
}
