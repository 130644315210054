.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.clear {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.link {
  cursor: pointer;
  text-decoration: none;
}

.link-base {
  cursor: pointer;
  text-decoration: none;
  color: #41A1D9;
  word-wrap: break-word;
}

.user-fullname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 160px;
  vertical-align: top;
}

.user-fullname-link {
  cursor: pointer;
  text-decoration: none;
  color: #41A1D9;
  word-wrap: break-word;
}

.link-bold {
  cursor: pointer;
  text-decoration: none;
  color: #41A1D9;
  font-weight: 600;
  word-wrap: break-word;
}

.dev-alert {
  display: inline-block;
  background: yellow;
  border: 1px dashed #cfc003;
  color: orange;
  padding: 4px;
  margin: 4px 0;
  > span:before {
    content: '👀';
    display: inline;
    margin-right: 4px;
  }
  &.top-right {
    position: absolute;
    top: -1px;
    right: -1px;
    margin-top: 0
  }
}

[hidden] {
  display: none!important;
}

.visibility-hidden {
  visibility: hidden !important;
}
