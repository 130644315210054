body {
  &.seo-debug {
    a {
      box-sizing: border-box;
      background-color: rgba(250, 250, 210, 0.5) !important;
      border: 1px dashed coral !important;
    }

    [itemscope] {
      border: 1px dashed violet !important;
      background-color: rgba(238, 130, 238, 0.3) !important;
    }

    div[itemprop], a[itemprop], span[itemprop] {
      position: relative;
      &:before {
        position: absolute;
        top: 2px;
        left: 2px;
        display: inline-block;
        background: violet;
        color: #fff;
        margin-right: 3px;
        content: "itemprop";
        font-size: 10px;
        line-height: 10px;
        padding: 0 3px;
      }
    }

    meta[itemprop] {
      display: inline-block;
      &:before {
        display: inline-block;
        background: green;
        color: #fff;
        margin-right: 3px;
        content: "<meta>";
        font-size: 10px;
        line-height: 10px;
        padding: 0 3px;
      }
    }

    svg {
      background: gray;
      fill: coral !important;
    }
  }
}
